import React from 'react';
import { Link } from 'react-router-dom';

export const CatchErrServer: React.VFC = () => {
    return (
        <>
            <div className={"errContent"}>
                <h2>
                    <img className='errContentImg' src="/images/shared/notFound.png" alt="404" />
                    <button className='errContenButton'><Link to="/">ホームに戻る</Link></button>
                </h2>
            </div>
        </>
    );
};