import { Outlet } from "remix";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { CatchErrServer } from "~/components/CatchErrServer";
import { MetaHeader } from "~/components/MetaHeader";
import { DefaultLayout } from "~/layouts/DefaultLayout";
import { useScrollToTop } from "~/hooks/useScrollToTop";
export default function App() {
  useScrollToTop()
  return (
    <html lang="ja">
      <Outlet />
    </html>
  );
}

export function ErrorBoundary() {
  useScrollToTop()
  return (
    <html>
      <MetaHeader title={"福岡カフェ日記とは - 福岡カフェ日記 | 福岡最大級のカフェメディア"} description={"福岡のカフェをキュレーションしたメディア、福岡カフェ日記。みなさまの好きが詰まったお店を紹介しています。ページをめくるように覗いていただけると嬉しいです。"} />
      <DefaultLayout>
        <CatchErrServer />
      </DefaultLayout>
    </html>
  );
}